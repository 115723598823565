@import 'src/utils/utils';

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #cb333b;
  background-color: rgba(255, 255, 255, 60%);

  &__spin {
    display: inline-block;
    width: 50px;
    height: 50px;

    @media(min-width: $md) {
      width: 100px;
      height: 100px;
    }
  }

  svg {
    display: block;
    width: 50px;
    height: 50px;

    @media(min-width: $md) {
      width: 100px;
      height: 100px;
    }

    path,
    rect {
      fill: currentcolor;
    }
  }
}